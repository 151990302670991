#home {
    height: 100%;
    background-image: url('../assets/images/background2.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: transform .5s ease-out;
    
    h2 {
        color: white;
        letter-spacing: 1px;
        font-family: Ubuntu;
        padding: 1em;
    }

    .logo {
        position: absolute;
        opacity: .6;
        left: 1em;
        bottom: 1em;
    }
}
