#template {
    min-height: 100vh;
    max-height: 100vh;
    background-color: transparent;

    .system-menu {
        background-color: darken(#f93f2e, 20%);
        border-radius: 0;
        margin: 0;
        * {
            color: white;
        }

        .item {
            letter-spacing: 1px;
            * {
                text-decoration: none;
            }
        }
        .item:not(.user) {
            font-weight: bold;
        }
    }

    .wrapper {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        padding: 0;
        background-color: transparent;

        .content {
            flex: 1;
            background-color: transparent;
        }
    }

    .page-content {
        padding: 0;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        -webkit-box-shadow: 4px 6px 34px 1px rgba(74,73,74,0.69);
        -moz-box-shadow: 4px 6px 34px 1px rgba(74,73,74,0.69);
        box-shadow: 4px 6px 34px 1px rgba(74,73,74,0.69);
    }

    .page-loader {
        height: 100%;
    }

    ::-webkit-input-placeholder {
        color: black;
        opacity: .5;
    }

    /* Firefox 18- */
    :-moz-placeholder {
        color: black;
        opacity: .5;
    }

    /* Firefox 19+ */
    ::-moz-placeholder {
        color: black;
        opacity: .5;
    }

    /* IE10+ */
    :-ms-input-placeholder {
        color: black;
        opacity: .5;
    }

    label {
        letter-spacing: .05rem;
        font-size: .9rem;
        opacity: .8;
    }

    .page-title {
        color: darken(#f93f2e, 20%);
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .tab-content {
        background: #F9FAFB;
        border-color: rgba(34, 36, 38, 0.15);
        box-shadow: 0px 2px 25px 0 rgba(34, 36, 38, 0.05) inset;
    }

    .item-tab.active {
        color: darken(#888888, 10%);
        border-bottom: 3px solid rgba(231, 26, 26, 0.582);
        background-color: lighten(#e2e2e2, 3%);
    }

    .item-tab {
        cursor: pointer;
        color: darken(#888888, 10%);
    }
}
