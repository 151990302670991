@import '../../../node_modules/semantic-ui-sass/scss/globals/variables';
@import '../../../node_modules/semantic-ui-sass/scss/globals/reset';
@import '../../../node_modules/semantic-ui-sass/scss/elements/all';
@import '../../../node_modules/semantic-ui-sass/scss/collections/all';
@import '../../../node_modules/semantic-ui-sass/scss/views/all';
@import '../../../node_modules/semantic-ui-sass/scss/modules/all';

// Bootstrap
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';

//Override variables
$enable-gradients: true;
$spacer: 1.5rem;

@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/utilities';
@import '../../../node_modules/bootstrap/scss/reboot';
@import '../../../node_modules/bootstrap/scss/grid';
@import '../../../node_modules/bootstrap/scss/forms';

html, body {
    min-height: 100vh;
    max-height: 100vh;
    margin: 0;
    * {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    background-color: lighten(#e2e2e2, 3%) !important;
}

#root {
    height: 100%;
}

::-webkit-input-placeholder {
    color: black !important;
    opacity: .7 !important;
}

/* Firefox 18- */
:-moz-placeholder {
    color: black !important;
    opacity: .7 !important;
}

/* Firefox 19+ */
::-moz-placeholder {
    color: black !important;
    opacity: .7 !important;
}

/* IE10+ */
:-ms-input-placeholder {
    color: black !important;
    opacity: .7 !important;
}

label {
    letter-spacing: .02rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: .9rem;
    opacity: .8;
}

.ui.input > input,
.ui.selection.dropdown {
    border: 1px solid rgba(0, 0, 0, 0.3) !important;
    :focus {
        border: 1px solid rgba(19, 36, 187, .7) !important;
    }
}

.ui.selection {
    .default.text {
        color: black !important;
        opacity: .7 !important;
    }
}

.ui.selection.dropdown .menu > .item {
    padding: 5px 15px !important;
}

.ui.dimmer {
    transition: background-color 0.5s ease;
    background-color: transparent;
    opacity: 1 !important;
}

.ui.modal {
    .header {
        background-color: #1a4b84;
        color: white;
    }
}

.ui.table tr.negative, .ui.table td.negative {
    background: #FFF6F6 !important;
    color: lighten(#9F3A38, 5%) !important;
    font-weight: bold;
}

.ui.table tr.warning, .ui.table td.warning {
    background: #FFFAF3 !important;
    color: lighten(#573A08, 15%) !important;
    font-weight: bold;
}

.ui.table tr.positive, .ui.table td.positive {
    background: #FCFFF5 !important;
    color: lighten(#2C662D, 5%) !important;
    font-weight: bold;
}

.ui.sidebar {
    overflow: visible !important;
}
.ui.table {
    * {
        font-size: .8rem !important;
    }
}

.ui.dropdown.selection {
    // * {
    //     font-size: .75rem;
    // }
    // line-height: ;
    .dropdown.icon {
        margin-right: -15px;
    }
}

.ui.tabular.menu {
    background: #FCFFF5;
}
